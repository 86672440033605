<template>
  <div class="home">
      
    <main>
    
 

<img class="ig-img" :src="posts.thumbnail_url" alt="image">


</main>





  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
//import $ from "jquery";
// import AboutMe from '@/components/AboutMe.vue';
import axios from "axios";
export default {
    data() {
        return {
           
            posts: []
        };
    },mounted(){

      axios
      .get('https://cdn.mydevhouse.dev/wp-json/wp/v2/team_member/727')
      .then((response) => {
        console.log(response.data.thumbnail_url);
        this.posts = response.data;
      });

     
      

    }
    //components: { AboutMe }
}
</script>

<style lang="scss">

</style>

