import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.min.css'

import bootstrap from 'bootstrap/dist/js/bootstrap.bundle'

import 'vue-fullpage.js/dist/style.css'

//import './fullpage.scrollHorizontally.min' // Optional. When using fullpage extensions
//import VueFullPage from 'vue-fullpage.js'
import VueAxios from 'vue-axios'
import router from './router'
import axios from    'axios';




//.use(VueFullPage)
createApp(App).use(router)
.use(bootstrap)
//.use(VueFullPage)
.use(VueAxios, axios)
.mount('#app');





